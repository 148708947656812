import { useEffect, useState } from "react";

import { UserAccountBalance } from '../uiux/user-balance'

export function UserBalance(...args) {

  const asset = arguments[0].children;
  const [loading,setLoading]=useState(true);
  const [data,setData]=useState({});

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setData(0);
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset]);

  if (loading)
  return (
    <>
      <UserAccountBalance 
        dataFromParent={'*'}
      />
    </>
  )

  return (
    <>
      <UserAccountBalance 
        dataFromParent={data.data}
      />
    </>
  )
}