import LayoutAuth from '../comp/site/layout-auth';
import { AuthLoginMobile } from '../comp/auth/login-mobile';

export default function LoginMobile() {
    return (
      <LayoutAuth>
        <AuthLoginMobile />
      
      </LayoutAuth>
    )
  }