import LayoutCenter from '../../comp/site/layout-center';
import { getLocalUser } from '../../apis/realm-auth';

import {SettingsProfile} from '../../comp/sets/settings-profile';

export default function SettingsProfileUs() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsProfile>
        {getLocalUser()}
      </SettingsProfile>

      </LayoutCenter>
  )
}