// user onboard - location (short - to update to full address)

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

import { getNewUser } from '../../apis/realm-auth';
import { fmupdateUserDomain } from '../../apis/realm-accs';

const list = ((require("../../data/data-userdomain.json")).data).filter(item => item.actv );

export function OnboardUserDomain() {
  
  const usrx = getNewUser();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState('');
  
  const [data,setData]=useState({
    domn:list.length
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data!=='') setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    console.log (list[val].code)
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form) {
      setLoading(true);

      const datx = {
        data: {
          domn: list[data.domn].code,
          usid: usrx.usid,
          form: 'us', 
        },
        user: usrx.usid,
        form: 'us'
      }
      console.log( JSON.stringify(datx) );
      const response = await fmupdateUserDomain( JSON.stringify(datx) );
      
      if (response.data) {
        history.push(`/onboard/creds`);
      }
      setLoading(false);
      //resetForm();
    }  
    else {
      setText('Please Select your option')
    }
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 24
      }}
    />


    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="p-3">

      <p className="text-muted">Select Your Expertise</p>

        {list.map((item,i) => (
        <div className={`mb-3`} key={i}>
          <div className="form-check p-0">
            <div className="d-flex py-2">
            <div className="me-auto">
                <p className="m-0 p-0">{item.name}</p>
              </div>
              <div className="">
                <input  className="form-check-input" 
                  type="checkbox"
                  style={{fontSize:"1.1em"}}
                  checked={data.domn===i}
                  onChange={({ target }) => handleChange("domn", i)}
                  >
                </input>
              </div> 
            </div>
          </div>
        </div>
        ))}
        
        <small className="text-danger">{text}</small>
      </div>
      
      <div className="p-3">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>
      
    </form>

  </>
  )
}