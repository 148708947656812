//Layout

const LayoutCenter = (props) => (

  <>  
    {/* content */}
    <div className="container-fluid">
      <div className="row">
        <div className="col"></div>
        <div className="col-lg-6">
          
          {props.children}
            
        </div>       
        <div className="col"></div>
      </div>
    </div>
    <div className="py-3"></div>
    <div className="py-3"></div>
  </>
)
  
export default LayoutCenter;