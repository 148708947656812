import LayoutAuth from '../comp/site/layout-auth';
import {OnboardUserComs} from '../comp/onbd/user-coms';

export default function OnboardComs() {
  return (
    <LayoutAuth>
      <OnboardUserComs />
      
    </LayoutAuth>
  )
}