import { Link } from "react-router-dom";
import LayoutAuth from '../comp/site/layout-auth';

import logo from '../brand/logo.png';

export default function Home() {

  return (
  <>
    <LayoutAuth>
      {/* main */}
      <div className="p-3 text-center">

        <img src={logo} width="60px" height="60px" alt="..." />
            
        <div className="py-3"></div>
        <p className="fw-bold text-primary m-0 p-0" style={{fontSize:"1.5em"}}>{process.env.REACT_APP_SITE_NAME}</p>
        <p className="lead text-muted">{process.env.REACT_APP_SITE_LINE}</p>

        <div className="" style={{height:"33vh"}} ></div>

        <div className="">
          <Link to="/account/login" className="btn btn-primary height-md rounded-md py-2 d-block">Login</Link>
          <div className="py-1"></div>
          <Link to="/account/register" className="btn btn-light height-md rounded-md py-2 d-block">Register</Link>
        </div>
      
      </div>      
              
    </LayoutAuth>
  </>
  )
}