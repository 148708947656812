import { useEffect, useState } from "react";
import { useRouteMatch } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import { HeaderInner } from '../uiux/hedr-inner';
import toast, { Toaster } from 'react-hot-toast';

import { fmgetUserSettingsPrivacy } from '../../apis/realm-accs';
import { fmUpdateUserSettingsSearch, fmUpdateUserSettingsAlerts } from '../../apis/realm-accs';

export function SettingsPrivacy(...args) {

  const asset = arguments[0].children;

  let {path} = useRouteMatch();
  const [sets, setSettings] = useState((path).toString().substr(13).charAt(0).toUpperCase() + (path).toString().substr(14));

  const [loading,setLoading]=useState(true);
  const [textSearch, setTextSearch] = useState('');
  const [statSearch, setStatusSearch] = useState(false);
  const [textAlert, setTextAlert] = useState('');
  const [statAlert, setStatusAlert] = useState(false);

  const [srch, setSearch] = useState({});
  const [alrt, setAlerts] = useState({});

  useEffect( () => {
    if (asset){
      const resp = async() => {
        var datx = (await fmgetUserSettingsPrivacy(JSON.stringify({ 
          data: {
            usid: asset._id.toString(),
            form: asset.form
          },
          user: asset._id.toString(),
          form: asset.form})
        )).data;

        if(datx) {
          setSearch(datx.srch);
          setAlerts(datx.coms);
        }
        setLoading(false);
      }
      resp()
    } else {}
  },[]);

  const handleChange = async(item, key, val) => {
    if (item === 'srch') {
      setSearch({ ...srch, [key]: val });
      updateSettingsSearch(key, val);
    }

    if (item === 'alrt') {
      setAlerts({ ...alrt, [key]: val });
      updateSettingsAlerts(key, val);
    }
    
  }

  const updateSettingsSearch = async(key, val) => {
    setTextSearch('');
    setTextAlert('');
    const datx = {
      data: {
        mode: key, stat: val,
        usid: asset._id.toString(),form: asset.form
      },
      user: asset._id.toString(),
      form: asset.form
    }
    //console.log (JSON.stringify(datx))
    const response = await fmUpdateUserSettingsSearch(JSON.stringify(datx));
    //console.log (response);
    if (response) {
      toast.success(`Settings Updated`);
      //setTextSearch('Settings Updated');
      setStatusSearch(true);
    }
    else {
      toast.error(`Settings not Updated`);
      //setTextAlert('Settings not updated');
    }
  }

  const updateSettingsAlerts = async(key, val) => {
    //console.log (key, val)
    setTextSearch('');
    setTextAlert('');
    const datx = {
      data: {
        mode: key, stat: val,
        usid: asset._id.toString(),form: asset.form
      },
      user: asset._id.toString(),
      form: asset.form
    }

    //console.log (JSON.stringify(datx))
    const response = await fmUpdateUserSettingsAlerts(JSON.stringify(datx));
    //console.log (response);
    if (response) {
      toast.success(`Settings Updated`);
      //setTextAlert('Settings Updated');
      setStatusAlert(true);
    }
    else {
      toast.error(`Settings not Updated`);
      //setTextAlert('Settings not updated');
    }
  }

  const handleSubmit = async (e) => {

  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <Toaster/>
    <HeaderInner 
      dataFromParent = {
      { name: `Settings: ${sets}`,
        desc: `Manage Your ${sets}`,
        link: `/${asset.form}/settings`
      }}
    />
    
      {/* search */}
      <div className="p-1">
        <p className="text-primary">Search and Discover</p>  

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Search by Mobile</p>
              <p className="small text-muted m-0 p-0">Enable search by mobile</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {srch.mmid}
                onChange={({ target }) => {
                  handleChange("srch", "mmid", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Search by Email</p>
              <p className="small text-muted m-0 p-0">Enable search by email</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {srch.emid}
                onChange={({ target }) => {
                  handleChange("srch", "emid", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Search by Name</p>
              <p className="small text-muted m-0 p-0">Enable search by name</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"
                checked = {srch.name}
                onChange={({ target }) => {
                  handleChange("srch", "name", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>


        {/* status */}
      <div className="">
        <small className={`text-${statSearch?'success':'danger'}`}>
          {textSearch}<span className={`text-muted`}>...</span>
        </small>
      </div>


      </div>



      {/* alerts */}
      <div className="p-1 pt-3">
        <p className="text-primary">Alerts and Notifications</p>  

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">SMS Alerts</p>
              <p className="small text-muted m-0 p-0">Receive alerts on SMS</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"
                checked = {alrt.mmid}
                onChange={({ target }) => {
                  handleChange("alrt", "mmid", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Email Alerts</p>
              <p className="small text-muted m-0 p-0">Receive alerts on Email</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"
                checked = {alrt.emid}
                onChange={({ target }) => {
                  handleChange("alrt", "emid", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        {/* status */}
        <div className="">
          <small className={`text-${statAlert?'success':'danger'}`}>
            {textAlert}<span className={`text-muted`}>...</span>
          </small>
        </div>

      </div>



      {/* action */}

  </>
  )
}