import LayoutAuth from '../comp/site/layout-auth';
import {OnboardUserDomain} from '../comp/onbd/user-domn';

export default function OnboardDomain() {
  return (
    <LayoutAuth>
      <OnboardUserDomain />
      
    </LayoutAuth>
  )
}