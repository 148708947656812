import * as Realm from 'realm-web';

// Realm App
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_MAIN });

// Function Calls
export const fmloginMobileUser = async (id) => {
  //login with mmid
  const credentials = Realm.Credentials.function({mmid:id})
  const user = await app.logIn(credentials);
  //console.log('mobile user - ',app.currentUser.id)
  return ({"auth":id, "usrx":user.id});
}

export const fmloginEmailUser = async (id) => {
  //login with mmid
  const credentials = Realm.Credentials.function({emid:id})
  const user = await app.logIn(credentials);
  //console.log('email user - ',app.currentUser.id)
  return ({"auth":id, "usrx":user.id});
}

export const fmloginUserProfiles = async (data) => {
  //user options for next
  const result = await app.currentUser.functions.authGetUserProfiles(data)
  console.log (result);
  return (result);
}

export const fmsendEmailPasscode = async (email) => {
  //generate email passcode  
  const credentials = Realm.Credentials.function(email)
  const user = await app.logIn(credentials);
  const result = await user.functions.authSendMailPasscode(email)
  return (result);
}

export const fmcheckEmailPasscode = async (email, code) => {
  //verify email passcode 
  const credentials = Realm.Credentials.function(email)
  const user = await app.logIn(credentials);
  const result = await user.functions.authVerifyMailPasscode(email, code);
  //console.log(app.currentUser.id);
  return (result);
}

export const fmlogoutUser=async()=>{
  // logout current user
  //console.log('user', app.currentUser)
  await app.currentUser.logOut()
  
  // reset local storage
  localStorage.clear();
  localStorage.setItem("user",null);
  localStorage.setItem("busn",null);
  //console.log('user', app.currentUser)
  //redirect user to homepage
  window.location.href='/';
}

export const setAuthUser=(asset)=>{
  localStorage.setItem('auth', JSON.stringify(asset));
}

export const getAuthUser=()=>{
  return JSON.parse(localStorage.getItem("auth"));
}

export const setLocalUser=(asset)=>{
  localStorage.setItem('user', JSON.stringify(asset));
}
  
export const getLocalUser=()=>{
  return JSON.parse(localStorage.getItem("user"));
}

export const setLocalBusiness=(asset)=>{
  localStorage.setItem('busn', JSON.stringify(asset));
}

export const getLocalBusiness=()=>{
  return JSON.parse(localStorage.getItem("busn"));
}

export const setNewUser=(asset)=>{
  localStorage.setItem('usne', JSON.stringify(asset));
}

export const getNewUser=()=>{
  return JSON.parse(localStorage.getItem("usne"));
}

export const setNewBusiness=(asset)=>{
  localStorage.setItem('bzne', JSON.stringify(asset));
}

export const getNewBusiness=()=>{
  return JSON.parse(localStorage.getItem("bzne"));
}