import * as Realm from 'realm-web';

// Realm App
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_MAIN });

// Function Calls

// send and verify passcode
export const fmsendPasscode = async (item) => {
  const result = app.currentUser.functions.utilSendPasscode(item);
  return result;
}

export const fmverifyPasscode = async (item) => {
  const result = app.currentUser.functions.utilVerifyPasscode(item);
  return result;
}
