import LayoutCenter from '../../comp/site/layout-center';

import { getLocalUser } from '../../apis/realm-auth';

import {SettingsAccountsMain} from '../../comp/sets/settings-accounts-main';

export default function SettingsAccountsMainUs() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsAccountsMain>
        {getLocalUser()}
      </SettingsAccountsMain>

      </LayoutCenter>
  )
}