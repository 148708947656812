import LayoutCenter from '../../comp/site/layout-center';
import { getLocalUser } from '../../apis/realm-auth';

import {SettingsSubs} from '../../comp/sets/settings-subs';

export default function SettingsSubsUs() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsSubs>
        {getLocalUser()}
      </SettingsSubs>

      </LayoutCenter>
  )
}