import avtr from '../../images/user.png';

export default function SettingsUserCard(...args) {

  const asset = arguments[0].children;

  return (
  <>
      {/* main */}

      {/* page */}

      {/* basic */}
      <div className="p-3 bg-soft rounded">

        <div className="row g-0">

          <div className="" style={{width:"fit-content"}}>
            <img src={asset.avtr || avtr} 
              className="rounded-circle shadow-sm" width="42" height="42" 
              style={{objectFit:"cover"}} alt="..."></img>
          </div>

          <div className="col ps-2">
            <p className="fw-bold text-primary m-0 p-0">{asset.name}</p>
            <p className="small m-0 p-0 line-clamp-sm">{asset.mmid}</p>
          </div>

        </div>

      </div>

  </>
  )
}