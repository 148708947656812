import { useEffect, useState } from "react";
import { useRouteMatch } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import { HeaderInner } from '../uiux/hedr-inner';

import { fmgetUserSettingsProfile } from '../../apis/realm-accs';

export function SettingsProfile(...args) {

  const asset = arguments[0].children;
  
  let {path} = useRouteMatch();
  const [sets, setSettings] = useState((path).toString().substr(13).charAt(0).toUpperCase() + (path).toString().substr(14));

  const [loading,setLoading]=useState(true);
  const [info, setInfo] = useState({});
  const [mmid, setMobile] = useState({});
  const [emid, setMail] = useState({});
  const [locs, setLocation] = useState({});
  const [othr, setOther] = useState({});
  
  useEffect( () => {
    if (asset){
      const resp = async() => {
        var datx = (await fmgetUserSettingsProfile(JSON.stringify({ 
          data: {
            usid: asset._id.toString(),
            form: asset.form
          },
          user: asset._id.toString(),
          form: asset.form})
        )).data;

        if(datx) {
          setInfo(datx.info);
          setMobile(datx.coms.find(item=>item.form==="mmid"));
          setMail(datx.coms.find(item=>item.form==="emid"));
          setLocation(datx.locs);
          setOther({crts:datx.crts, mots:datx.mots})
        }
        setLoading(false);
      }
      resp()
    } else {}
  },[]);

  const handleChange = async(item, key, val) => {

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderInner 
      dataFromParent = {
      { name: `Settings: ${sets}`,
        desc: `Manage Your ${sets}`,
        link: `/${asset.form}/settings`
      }}
    />

    <form onSubmit={handleSubmit}>
      {/* basic info */}
      <div className={`p-1`}>
        <p className="text-primary">Account Info</p> 

        <div className="border p-3 bg-soft rounded mb-3">
          <div className="d-flex">
            <div className="me-auto">
              <p className="fw-bold m-0 p-0">{info.name}</p>
              <p className="small">{info.form === 'us' ? 'Individual' : 'Business'} Account</p>
              <p className="small m-0 p-0">
                <span className={`small text-${info.actv ? 'success' : 'lite'}`}>●</span>
                <span className="small text-dark">{' '}{info.actv ? 'Active' : 'Inactive'}</span>
              </p>
            </div>
            <div className="">
              <img src={info.avtr} className="rounded-circle" 
                height="42" width="42" alt="..."></img> 
            </div>
          </div>
        </div>

      </div>


      {/* comms info */}
      <div className="p-1 pt-3">
        <p className="text-primary">Contact Info</p>

        <div className="mb-3 pb-3 border-bottom">
          <p className="small m-0 p-0">Mobile</p>
          <p className="fw-bold m-0 p-0">{mmid.nmbr}</p>

          <p className="small m-0 p-0">
            <span className={`small text-${mmid.stat ? 'success' : 'lite'}`}>●</span>
            <span className="small text-dark">{' '}{mmid.stat ? 'Verified' : 'Not Verified'}</span>
          </p>
        </div>

        <div className="mb-3 pb-3 border-bottom">
          <p className="small m-0 p-0">Email</p>
          <p className="fw-bold m-0 p-0">{emid.nmbr}</p>
          <p className="small m-0 p-0">
            <span className={`small text-${emid.stat ? 'success' : 'lite'}`}>●</span>
            <span className="small text-dark">{' '}{emid.stat ? 'Verified' : 'Not Verified'}</span>
          </p>
          
        </div>

      </div>

      {/* location info */}
      <div className="p-1 pt-3">
        <p className="text-primary">Address</p>  

        <div className="mb-3">
          <p className="m-0 p-0">{locs.lobl}</p>
          <p className="m-0 p-0">{locs.losr}</p>
          <p className="m-0 p-0">{locs.loar}</p>
          <p className="m-0 p-0">{locs.lolc}</p>

          <p className="m-0 p-0">{locs.loct}</p>
          <p className="m-0 p-0">{locs.lodt}</p>
          <p className="m-0 p-0">{locs.lost}</p>
          <p className="m-0 p-0">{locs.locn}{' '}{locs.loco}</p>
        </div>

      </div>

      {/* action */}
      <div className="p-1 pt-3">
        <p className="text-primary">Options</p>
        <p className="small text-muted p-0 m-0">To Update your Profile, Please Submit a ticket</p>
        <p className="small text-muted p-0 m-0">To Close your account, Please Submit a ticket</p>
      </div>

      {/* other info */}
      <div className="p-1 pt-3">
        <div className="border-bottom"></div>
        <p className="text-primary"></p>
        <div className="mb-3 small text-muted">
        <p className="m-0 p-0">{info.enid}</p>
        <p className="m-0 p-0">{'Created: '}{(new Date(othr.crts)).toISOString()}</p>
        <p className="m-0 p-0">{'Last Updated: '}{(new Date(othr.mots)).toISOString()}</p>
        </div>

      </div>

    </form>

  </>
  )
}