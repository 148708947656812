// Web Navigation
import { Link } from "react-router-dom";

import { ActiveLink } from '../../apis/app-helper';

const pars = (require("../../data/navs-web.json")).data;
const list = (pars.find(item => item.locc === "foot")).list;

export default function MobFooter() {

  return (
  <>
    {/* nav */}
    <div className="bg-light border-top">
      <div className="container-fluid">
        <div className="row g-0 py-2">
          {/* brand */}

          
          {/* options */}
          {list && list.map((item, i) => (
          <div className="col px-0 text-center" key={i}>
            
              <Link to={`${(item.name).toLowerCase()}`}
                className={`nav-link m-0 py-0 ${(item.name).toLowerCase() === ActiveLink() ? 'text-primary' : 'text-dark'}`}>
                
                <i className={`m-0 p-0 ${item.icon}${(item.name).toLowerCase() === ActiveLink() ? '-fill' : ''}`}
                
                style={{fontSize:"1.35em", lineHeight:"0"}}></i>
              <p className="text-mini m-0 p-0">{item.name}</p>
              </Link>
              
          </div>
          ))}
          {/* user */}


        </div>
      </div>
    </div>
  </>
  )
}