// user onboard - docs - upload cred document attachments

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

import AWSDocUpload from '../../apis/aws-doc-upload';

import { getNewUser } from '../../apis/realm-auth';
import { fmupdateUserDocs } from '../../apis/realm-accs';


export function OnboardUserDocs() {
  
  const usrx = getNewUser();
  const history = useHistory();

  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState([
    {
      name: 'Aadhaar Card',
      desc: 'UIDAI Aadhaar Card',
      form: 'adhr',
      nmbr: '',
      file: '',frnt: '', back: '',
      stat: false, dcvr: false, actv: false
    },{
      name: 'PAN Card',
      desc: 'NSDL PAN Card Number',
      form: 'panc',
      nmbr: '',
      file: '',frnt: '', back: '',
      stat: false, dcvr: false, actv: true
    },{
      name: 'Voter Card',
      desc: 'Govt Voter Card Number',
      form: 'votr',
      nmbr: '',
      file: '',frnt: '', back: '',
      stat: false, dcvr: false, actv: false
    },{
      name: 'Driver License',
      desc: 'RTO Driver License Number',
      form: 'drvl',
      nmbr: '',
      file: '',frnt: '', back: '',
      stat: false, dcvr: false, actv: false
    },      
  ]);

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    var docs = data.reduce( (a, c) => a + (c.stat?1:0) , 0);
    if (docs===1) setForm(true);
  },[data]);

  const handleChange = (key, val, indx) => {
    // edit array-object value
    setData(data => data.map((item, i) => (indx === i ? { ...item, [key]: val } : item)));
  };

  const updateDocLink = async(file) => {
    console.log (file);
    if (file !== 'errr') {
      var docx = (file.substr(77, file.length)).split('.')[0];
      
      handleChange("file", file, data.findIndex(item=>item.form===docx));
      handleChange("stat", true, data.findIndex(item=>item.form===docx));   
      
      //setText('');
    }
    else {}
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) {
      setLoading(true);
      //console.log (data)
      const datx = {
        data: {
          docs: (data.filter(item => item.actv)).map(item => 
            ({'form':item.form, 'file':item.file})),
          usid: usrx.usid,
          form: 'us',
        },
        user: usrx.usid,
        form: 'us'
      }
      
      console.log( JSON.stringify(datx) );
      
      const response = await fmupdateUserDocs( JSON.stringify(datx) );
      console.log(response);
      if (response.data) {
        history.push(`/onboard/location`);
      }

      setLoading(false);
    }
    else {
      setText('Please upload required documents')
    }
    
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 33
      }}
    />


    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="p-3">

      {/* list of document uploads */}
      {data && data.length > 0 && data.map((item, i) => ( item.actv ?
        <div className="mb-3 border-bottom" key={i}>
          <div className="form-group mb-3">
            <p className="form-label fw-bold small m-0 p-0">{item.name} <span className="text-danger">*</span></p>
            <p className="form-label text-muted small">{item.desc}</p>
            <input type="text" className="form-control height-md bg-light d-none"
              value={item.nmbr}
              onChange={(e) => handleChange("nmbr", e.target.value, i)}
              placeholder="Enter Number">
            </input>
          </div>
          <AWSDocUpload 
            dataFromParent = {`${usrx.usid}-${item.form}`}
            parentCallback = {updateDocLink}
          />
          <div className="py-3">
            <span className="small">Status: </span>
            <span className={`small ${item.stat?'text-success':'text-lite'}`}>{item.stat
              ?'● Success'
              :'● Pending'}</span>
          </div>
        </div>
        :''))}


        <small className="text-danger">{text}</small>
      </div>
      
      <div className="p-3">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

  </>
  )
}