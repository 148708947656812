import LayoutAuth from '../comp/site/layout-auth';
import {OnboardUserTerms} from '../comp/onbd/user-terms';

export default function Onboard() {
  return (
    <LayoutAuth>
      <OnboardUserTerms />
      
    </LayoutAuth>
  )
}