//Layout

const LayoutAuth = (props) => (

  <>
    {/* nav */}
      
    {/* content */}
    <div className="container-fluid bg-white">
      <div className="row">
        <div className="col"></div>
        <div className="col-md-6 col-lg-4" style={{maxWidth:"420px"}}>
          <div className="pt-3"></div>
          
          {props.children}
            
        </div>       
        <div className="col"></div>
      </div>
    </div>

    {/* footer */}
    <div className="py-3"></div>
    <div className="py-3"></div>
  </>
)
  
export default LayoutAuth;