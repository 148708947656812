import LayoutStacked from '../../comp/site/layout-stacked';


export default function Menu4() {

  return (
    <LayoutStacked>


    </LayoutStacked>
  )
}