// new user account created here
// user onboard - name, photo, mmid or emid, obnm = true

import { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

export function OnboardUserAvatar() {
  
  const history = useHistory();
  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    name: '',
    emid: '',
    orgs: ''
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.emid !=='') 
      setForm(true);
  });


  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    history.push('/onboard/creds')

  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 15
      }}
    />

    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="p-3">

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Name <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-light"
              required
              value={data.name}
              onChange={({ target }) => {handleChange("name", target.value); setText('');}}
              placeholder="Enter Full Name">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Email  <span className="text-danger">*</span></label>
            <input type="email" className="form-control height-md bg-light"
              value={data.emid}
              onChange={({ target }) => {handleChange("emid", target.value); setText('');}}
              placeholder="user@mail.com">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Organization </label>
            <input type="text" className="form-control height-md bg-light"
              required
              value={data.orgs}
              onChange={({ target }) => { handleChange("orgs", target.value); setText(''); }}
              placeholder="Enter Organization Name"></input>
          </div>
        </div>

        <div className=""></div>
        <small className="text-danger">{text}</small>

        <div className="pt-3 d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>

      </div>
    </form>

  </>
  )
}