import React from "react";
import { Link } from 'react-router-dom';

export class HeaderOnboard extends React.Component {
  constructor(props) {
    super(props);
    this.item = this.props.dataFromParent;
  }

  render() {
    return (
      <>
      <div className="p-3">
        <div className="d-flex mb-2">
          <div className="me-auto">
            <p className="lead text-primary m-0 p-0">{this.item.name}</p>
            <small className="">{this.item.desc}</small>
          </div>
          <div className="">
            <Link 
              className="text-secondary"
              to={this.item.link}
            >
            <i className='bi-x' style={{fontSize:"2em", lineHeight:"0"}}></i>
            </Link>
          </div>
        </div>

        <div className="progress" style={{height:".42em"}}>
          <div className="progress-bar bg-success" role="progressbar" 
            style={{width:`${this.item.stat}%`}} 
            aria-valuenow={this.item.stat}
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      </>
    );
  }
}