import LayoutStacked from '../../comp/site/layout-stacked';

import { getLocalUser } from '../../apis/realm-auth';
import {UserBalance} from '../../comp/dash/user-balance';

function Dashboard() {

  const user = getLocalUser();
  console.log (user)

  return (
    <LayoutStacked>
    <div className="mb-3"></div>
    <UserBalance>
      {user}
    </UserBalance>
      
    </LayoutStacked>
  )
}

export default Dashboard;