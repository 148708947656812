import LayoutAuth from '../comp/site/layout-auth';
import {OnboardUserLocation} from '../comp/onbd/user-location';

export default function Onboard() {
  return (
    <LayoutAuth>
      <OnboardUserLocation />
      
    </LayoutAuth>
  )
}