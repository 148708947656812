//Layout

const year = new Date().getFullYear()

const WebFooter = () => (
  
  <>
    {/* nav */}
    <div className="py-3"></div>
    <div className="bg-transparent border-top">
      <div className="pb-3"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            
            <p className="text-small text-muted">
            {'©'} {year} {process.env.REACT_APP_SITE_NAME}
              {' - '}{process.env.REACT_APP_SITE_LINE}
            </p>
          </div>
        </div>
      </div>
    </div>

  </>
)
  
export default WebFooter;