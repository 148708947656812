// Realm App
import * as Realm from 'realm-web';
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_MAIN });

// Function Calls
export const fmgetUserAccounts = async (item) => {
  // get all user accounts
  const result = app.currentUser.functions.acntGetUserAccountsList(item);
  return result;
}

export const fmgetUserLinkedAccountsList = async (item) => {
  // get user settings - accounts
  const result = await app.currentUser.functions.acntGetUserLinkedAccountsList(item)
  //console.log (result);
  return (result);
}

export const fmgetUserLinkedAccountsMain = async (item) => {
  // get primary linked account
  const result = app.currentUser.functions.acntGetUserLinkedAccountsMain(item);
  return result;
}

export const fmUpdateUserLinkedAccountsMain = async (item) => {
  // update primary linked account
  const result = await app.currentUser.functions.acntUpdateUserLinkedAccountsMain(item)
  return (result);
}

export const fmgetBaseAccountBalance = async (item) => {
  // get base account balance
  const result = app.currentUser.functions.acntGetBaseAccountBalance(item);
  return result;
}

export const fmgetBaseAccountTodayStats = async (item) => {
  // get base account balance
  const result = app.currentUser.functions.statGetUserDashboardSummary(item);
  return result;
}

//link accounts
export const fmcreateUserLinkAccounts = async (item) => {
  // link upi, bank or other account
  const result = app.currentUser.functions.acntCreateLinkAccount(item);
  return result;
}

export const fmcreateVirtualAccount = async (item) => {
  // create virtual account
  const result = app.currentUser.functions.acntCreateVirtualAccount(item);
  return result;
}

//verify accounts
export const fmverifyUPIAccount = async (item) => {
  // verify UPI 
  const result = app.currentUser.functions.acntVerifyUPIAccount(item);
  return result;
}

export const fmverifyBankAccount = async (item) => {
  // verify bank account
  const result = app.currentUser.functions.acntVerifyBankAccount(item);
  return result;
}

export const fmverifyVirtualAccount = async (item) => {
  // verify virtual account
  const result = app.currentUser.functions.acntVerifyVirtualAccount(item);
  return result;
}

export const fmverifyPayTMWalletAccount = async (item) => {
  // verify PAYTM wallet - NA

}

export const fmverifyGPayWalletAccount = async (item) => {
  // verify GPAY wallet - NA

}
