//Layout
import WebHeader from './web-header';
import WebFooter from './web-footer';
import MobFooter from './mob-footer';

const LayoutStacked = (props) => (

  <>
    {/* nav */}
    <div className="sticky-top">
      <WebHeader />
    </div>
    
    {/* content */}
    <div className="">
      <div className="">
        <div className="">
          
          {props.children}

          <div className="py-3"></div>
          <div className="py-3"></div>
        </div>
      </div>
    </div>

    {/* footer */}
    <div className="d-none d-sm-block">
      <WebFooter  />
    </div>
    <div className="d-md-none">
      <div className="py-3"></div>
      <div className="py-3"></div>
    </div>
    <div className="d-md-none fixed-bottom">
      <MobFooter  />
    </div>
  
  </>
)
  
export default LayoutStacked;