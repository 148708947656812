import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {HeaderAuth} from '../uiux/hedr-auth';
import { Loader } from '../uiux/site-loader';

import { fmloginMobileUser, setAuthUser } from "../../apis/realm-auth";

function Register() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [btnPassword, setButtonPassword] = useState(true);
  const [btnLogin, setButtonLogin] = useState(false);
  const [text, setText] = useState("");
  
  const [loading,setLoading]=useState(false);
  const history = useHistory();

  const refreshPage = ()=> {
    window.location.reload(false);
  }

  const handlePassword = async () => {
    const user = { username, password };
    if (user.username==="") {
      setText('Please enter Mobile Number');
    } else {
      //send OTP-Password to user
      //add firebase OTP option here
      setText('Passcode (OTP) sent');

      setButtonPassword(false);
      setButtonLogin(true);
      //console.log(response);
    }

  }

  const handleSubmit = async e => {
    e.preventDefault();    
    localStorage.clear();

    const user = { username, password };
    
    if (user.username==="" || user.password==="" ) {
      setText('Mobile Number and OTP should not be empty');
    } else {
      if (password==="123456"){
        setLoading(true);
        setText('Passcode Verified');
        
        const response = await fmloginMobileUser(`91${username}`);
        setAuthUser(response);
        //console.log(response);
        setLoading(false);
        history.push('/onboard/name')
      }
      else {
        setText('Incorrect Passcode. Please try again.');
      }
    }

  };

  if (loading){
    return (
    <>
      <Loader />
    </>
    )
  }

  return (
  <>

    <HeaderAuth 
      dataFromParent = {
      { name: 'New Account',
        desc: 'Please verify your mobile number',
        link: `/`
      }}
    />

    {/* main */}    
    <form onSubmit={handleSubmit}>
      <div className="p-3">
      
        <div className="mb-3">
          <label className="form-label small">Mobile Number</label>
          <input type="text" 
            
            className="form-control height-md bg-soft" 
            disabled={btnLogin}
            value={username}
            onChange={({ target }) => {setUsername(target.value); setText('');}}
            placeholder="9876540123">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">OTP / Passcode</label>
          <input type="password" 
            className="form-control height-md bg-soft" 
            disabled={btnPassword}
            value={password}
            onChange={({ target }) => {setPassword(target.value); setText('');}}
            placeholder="Enter OTP">
          </input>
        </div>
        
        {/* action */}
        <div className={`mb-3 d-grid ${btnPassword? '' : 'd-none'}`}>
          <button type="button"
            disabled={username==='' || username.length!==10}
            className="btn btn-primary height-md"
            onClick={()=>handlePassword()}
            ><small>Get Passcode</small>
          </button>
        </div>

        <div className={`mb-3 d-grid ${btnLogin? '' : 'd-none'}`}>
          <button type="submit" 
            disabled={password==='' || password.length!==6}
            className="btn btn-primary height-md">
            <small>Login</small>
          </button>
        </div>

        <div className=""></div>
        <small className="text-danger">{text}</small>
      
      </div>

    </form>  

    <div className="d-flex p-3">
      <div className="me-auto">
        <button 
          className="btn btn-link text-decoration-none p-0 text-muted"
        ><small>Resend OTP</small></button>
        <div className="pb-3"></div>
      </div>
      
      <div className="">
        <button 
          className="btn btn-link text-decoration-none p-0 text-muted"
          onClick={()=>refreshPage()}
        ><small>Change Mobile Number</small></button>

      </div>
      
    </div>

    
    <div className="px-3">
      <div className="pb-3 border-top"></div>
      <small className="text-muted">Already Have an Account: </small>
      <Link to='/account/login' className="small link-primary">Login Here</Link>
    </div>

  </>
  )
}
  
export default Register;