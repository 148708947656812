import LayoutCenter from '../../comp/site/layout-center';
import { getLocalUser } from '../../apis/realm-auth';

import {SettingsReferrals} from '../../comp/sets/settings-referrals';

export default function SettingsReferralsUs() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsReferrals>
        {getLocalUser()}
      </SettingsReferrals>

      </LayoutCenter>
  )
}