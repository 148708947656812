import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Loader } from '../uiux/site-loader';
import logotext from '../../brand/logo-text.png';

import { fmloginUserProfiles } from '../../apis/realm-auth';
import { getAuthUser, setLocalUser, setLocalBusiness } from '../../apis/realm-auth';
import { setNewUser, setNewBusiness } from '../../apis/realm-auth';
import { fmgetBusinessInfo, fmgetUserInfo } from '../../apis/realm-accs';

import * as Realm from 'realm-web';
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_MAIN });

function refreshPage() {
  window.location.reload(false);
}

function Next() {

  const asset = getAuthUser();
  const [user,setUser]=useState({});
  const [loading,setLoading]=useState(true);
  console.log (asset)

  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      if (app.currentUser === null) {      
        const credentials = Realm.Credentials.function({emid:asset.auth});
        await app.logIn(credentials);
        refreshPage();
      } else {
        const profile = (await fmloginUserProfiles (JSON.stringify({
          data:{user:asset.auth}
          })
        )).data;
        
        var options = [{name:'', form:'', actv:false, onbx: false, self: false, usid:'' }];
        
        if (profile) {
          options = profile;
          options.forEach (item => 
            item.onbx = (
              item.onbd.obnm && item.onbd.obcr && item.onbd.obdc 
              && item.onbd.oblc && item.onbd.obcm && item.onbd.obtr
            )
          )
        }

        setUser(options);
        console.log (options)
        //console.log (app.currentUser)
        //setUserStatus(user);
        setLoading(false);
      }
    }
    fetchData();
  }, [asset.auth]);

  const newUser = () => {
    setNewUser({usid:''})
    if (user) {
      setNewUser({usid:user[0].usid})
      console.log(user[0].usid)
    }
    history.push(`/onboard/name`)
  }


  const onboardUser = () => {
    // user should exist
    setNewUser({usid:user[0].usid})
    console.log(user[0].usid)

    if (!user[0].onbd.obnm) history.push(`/onboard/name`)
    else {
      if (!user[0].onbd.obcr) history.push(`/onboard/cred`)
      else {
        if (!user[0].onbd.obdc) history.push(`/onboard/docs`)
        else {
          if (!user[0].onbd.oblc) history.push(`/onboard/location`)
          else {
            if (!user[0].onbd.obcm) history.push(`/onboard/contact`)
            else {
              if (!user[0].onbd.obtr) history.push(`/onboard/terms`)
            }
          }
        }
      }
    }
  }

  const onboardBusiness = (user) => {
    // business should exist
    if (user.form === 'bz'){
      setNewBusiness({bsid:user.usid})
      console.log(user.usid)
    }
    
    if (!user.onbd.obnm) history.push(`/onboard/name`)
    else {
      if (!user.onbd.obcr) history.push(`/onboard/cred`)
      else {
        if (!user.onbd.obdc) history.push(`/onboard/docs`)
        else {
          if (!user.onbd.oblc) history.push(`/onboard/location`)
          else {
            if (!user.onbd.obcm) history.push(`/onboard/contact`)
            else {
              if (!user.onbd.obtr) history.push(`/onboard/terms`)
            }
          }
        }
      }
    }
  }

  const newBusiness = () => {
    setNewBusiness({bsid:''})
    history.push(`/onboard/bz/new`)
  }

  const usDashboard = () => {
    //window.location.href = `/us/dashboard`
    history.push(`/us/dashboard`)
  }

  const bzDashboard = () => {
    history.push(`/bz/dashboard`)
  }


  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  // user does not exist or user was added contact or refer
  if (!(user && user[0].self)) {
    return (
      <>
        <div className="p-3">
          <img src={logotext} height="20" alt="..." />
        </div>

        <div className="p-3">
        
          <div className="d-flex rounded bg-soft p-3">
            <div className="">
              <i className={`bi-check-circle text-primary`}  
                style={{fontSize:"2.1em", }}>
              </i>
            </div>
            <div className="ps-3">
              <p className="lead m-0 p-0">Welcome Onboard</p>
              <small className="text-muted">New Account</small>
            </div>
            
          </div>
          <div className="bg-warning"></div>
          
        </div>

        <div className="p-3">
          
          <p className="text-medium">Please use the Setup Account Link below to setup your account.</p>
          <p className="text-medium">Once your account is setup, You will be able to login with your Mobile number and OTP.</p>
        </div>

        <div className="p-3">
          <button onClick={async ()=> { newUser(); }} 
            className="btn btn-link text-decoration-none link-primary p-0">
            Setup Account
          </button>
        
        </div>

        {/* create a new user here and then onboard */}

        <div className="p-3">
          <hr></hr>
          <Link to={`/account/logout`}
            className="link-danger"
          ><small>Logout</small></Link>
        </div>

      </>
    )
  }

  // user has incomplete onboarding steps
  if (!(user && user[0].onbx)) {
    return (
      <>
        <div className="p-3">
          <img src={logotext} height="20" alt="..." />
        </div>

        <div className="p-3">
          <div className="d-flex rounded bg-soft p-3">
            <div className="">
              <i className={`bi-exclamation-circle text-danger`}  
                style={{fontSize:"2.1em", }}>
              </i>
            </div>
            <div className="ps-3">
              <p className="lead m-0 p-0">Profile Incomplete</p>
              <small className="text-muted">Incomplete Account Information</small>
            </div>
          </div>
        </div>

        <div className="p-3">
          <p className="text-medium">Your account information is incomplete.</p>
          <p className="text-medium">Please use the Update Account Information Link below to complete your account setup.</p>
        </div>

        <div className="p-3">
          <button onClick={async ()=> { onboardUser();}} 
            className="btn btn-link text-decoration-none link-primary p-0">
            Update Account
          </button>
        </div>

        <div className="p-3">
          <hr></hr>
          <Link to={`/account/logout`}
            className="link-danger"
          ><small>Logout</small></Link>
        </div>

      </>
    )
  }

  if (!(user && user[0].actv)) {
    return (
      <>

        <div className="p-3">
          <img src={logotext} height="19" alt="..." />
        </div>

        <div className="p-3">
        
          <div className="d-flex rounded bg-soft p-3">
            <div className="">
              <i className={`bi-shield text-primary`}  
                style={{fontSize:"2.1em", }}>
              </i>
            </div>
            <div className="ps-3">
              <p className="lead m-0 p-0">Account In Review</p>
              <p className="small text-muted  m-0 p-0">
              Your account is in review 
              </p>
            </div>
            
          </div>
          <div className="bg-warning"></div>
          
        </div>

        <div className="p-3">
          <p className="text-medium">We are reviewing your account information.</p>
          <p className="text-medium">Once your account review is completed, You will be able to login with your Mobile number and OTP.</p>
        </div>

        <div className="p-3">
          <hr></hr>
          <Link to={`/account/logout`}
            className="link-danger"
          ><small>Logout</small></Link>
        </div>

      </>
    )
  }


  if ((user && user[0].hold)) {
    return (
      <>

        <div className="p-3">
          <img src={logotext} height="19" alt="..." />
        </div>

        <div className="p-3">
        
          <div className="d-flex rounded bg-soft p-3">
            <div className="">
              <i className={`bi-shield text-primary`}  
                style={{fontSize:"2.1em", }}>
              </i>
            </div>
            <div className="ps-3">
              <p className="lead m-0 p-0">Account On Hold</p>
              <p className="small text-muted  m-0 p-0">
              Your account is on Hold
              </p>
            </div>
            
          </div>
          <div className="bg-warning"></div>
          
        </div>

        <div className="p-3">
          <p className="text-medium">We are reviewing your account information.</p>
          <p className="text-medium">Once your account review is completed, You will be able to login with your Mobile number and OTP.</p>
        </div>

        <div className="p-3">
          <hr></hr>
          <Link to={`/account/logout`}
            className="link-danger"
          ><small>Logout</small></Link>
        </div>

      </>
    )
  }


  return (
    <>
    {/* main */}
    <div className="p-3">
      <p className="lead text-primary m-0 p-0">Account Options</p>
      <small>Please select an account to continue</small>
    </div>

    {/* personal */}
    <div className={ user.length !== 0 ? 'p-3': 'd-none'}>
      <p className="small text-muted fw-bold">Personal Account(s)</p>
        
      {user && user.length > 0 && user.map((item, i) => ( item.form === "us" ?

      <div className="d-flex mb-3" key={i}>
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">{ user ? item.name : 'User Name'}</p>
          <p className={`small text-muted m-0 p-0 ${item.onbx ? 'd-none' : ''}`}>
            <span className={`small text-${item.onbx?'success':'danger'}`}>
              <i className={`bi-exclamation-circle-fill small`}></i>
            </span>{' '}
            {item.onbx ? 'Complete' : 'Incomplete Profile'}
          </p>
          <p className={`small text-muted m-0 p-0 ${item.onbx?'':'d-none'}`}>
            <span className={`small text-${item.actv?'success':'warning'}`}>
              <i className={`bi-circle-fill small`}></i>
            </span>{' '}
            {item.actv ? 'Active' : 'In Review'}
          </p>
        </div>
        <div className="p-0">
          <button onClick={async ()=> {
              setLocalUser((await fmgetUserInfo(item.usid)).data);
              usDashboard();
            }} 
            className="btn btn-link small text-decoration-none p-0">
            <small>Select</small>
          </button>
        </div>
      </div>
      
      : ''))}

    </div>

    {/* business */}
    <div className={ user.length > 1 ? 'p-3': 'd-none'}>
      <p className="small text-muted fw-bold">Business Account(s)</p>
        
      {user && user.length > 1 && user.map((item, i) => ( item.form === "bz" ?

      <div className="d-flex mb-3" key={i}>
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">{ user ? item.name : 'Business Name'}</p>
          <p className={`small text-muted m-0 p-0 ${item.onbx ? 'd-none' : ''}`}>
            <span className={`small text-${item.onbx?'success':'danger'}`}>
              <i className={`bi-exclamation-circle-fill small`}></i>
            </span>{' '}
            {item.onbx ? 'Complete' : 'Profile Incomplete'}
          </p>
          <p className={`small text-muted m-0 p-0 ${item.onbx?'':'d-none'}`}>
            <span className={`small text-${item.actv?'success':'warning'}`}>
              <i className={`bi-circle-fill small`}></i>
            </span>{' '}
            {item.actv ? 'Active' : 'In Review'}
          </p>
        </div>
        <div className={`p-0  ${item.onbx ? 'd-none' : ''}`}>
          <button onClick={async ()=> {
              onboardBusiness(item);
            }}
            className={`btn btn-link small text-decoration-none p-0`}>
            <small>Update</small>
          </button>
        </div>
        <div className={`p-0  ${item.onbx? '': 'd-none'}`}>
          <button onClick={async ()=> {
              setLocalBusiness((await fmgetBusinessInfo(item.usid)).data);
              bzDashboard();
            }} 
            disabled={!item.actv}
            className={`btn btn-link small text-decoration-none p-0`}>
            <small>Select</small>
          </button>
        </div>
      </div>
      
      : ''))}

    </div>

    {/* actions */}
    <div className="p-3">
      <hr></hr>
      <button 
        onClick={ async ()=> {
          //console.log (user);
          setLocalUser((await fmgetUserInfo(user[0].usid)).data);
          newBusiness();
        }}
        type="button"
        className="btn btn-link text-decoration-none p-0">
        Add Business Account
      </button>
    
      <div className="py-2"></div>
      
      <Link to={`/account/logout`}
        className="link-danger"
      ><small>Logout</small></Link>
    </div>

    
  </>
  )
}
  
export default Next;