import LayoutCenter from '../../comp/site/layout-center';
import { getLocalUser } from '../../apis/realm-auth';

import {SettingsPrivacy} from '../../comp/sets/settings-privacy';

export default function SettingsPrivacyUs() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsPrivacy>
        {getLocalUser()}
      </SettingsPrivacy>

      </LayoutCenter>
  )
}