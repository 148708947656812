// user onboard - location (short - to update to full address)

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

import LocationSearchBar from '../../apis/google-location';
import { getNewUser } from '../../apis/realm-auth';
import { fmupdateUserLocs } from '../../apis/realm-accs';

const stateList = (require("../../data/data-statelist.json")).data;

export function OnboardUserLocation() {
  
  const usrx = getNewUser();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState('');
  
  const [data,setData]=useState([]);
  const [place,setPlace]=useState(0);

  const [location, setLocation] = useState({
    premise:'',
    route:'',
    area:'',
    neighborhood: '',
    sublocality_level_2: '',
    sublocality_level_1: '',
    locality: '',
    administrative_area_level_2: '',
    administrative_area_level_1: '',
    country: 'India',
    postal_code: ''
  });

  useEffect( () => {
    data.forEach (item => 
      setLocation({ ...location, [item.types[0]]: item.long_name })
    )
  },[place]);

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (
      //location.premise!=='' && location.route !=='' &&
      //location.area!=='' && 
      //location.sublocality_level_2!=='' && location.sublocality_level_1 !=='' &&
      location.locality!=='' && 
      location.administrative_area_level_2!=='' && 
      location.administrative_area_level_1 !=='' &&
      location.country!=='' &&
      location.postal_code!=='' && location.postal_code.length===6
    )
      setForm(true);
  },[location]);


  const resetLocation = () => {
    location.premise =''
    location.route =''
    location.area =''
    location.neighborhood =''
    location.sublocality_level_2 =''
    location.sublocality_level_1 =''
    location.locality =''
    location.administrative_area_level_2 =''
    location.administrative_area_level_1 =''
    location.country =''
    location.postal_code ='' 
  }

  const userLocation = (locs) => {
    //console.log (locs);
    setData(locs.address_components);
    setPlace(locs.place_id);
    resetLocation();
    locs.address_components.forEach(item => {
      //console.log (item.types[0], item.long_name);
      location[item.types[0]] = item.long_name;
    });
    
    if (!locs.address_components.filter(item => item.types[0]==='sublocality_level_2')) 
      {location.sublocality_level_2=''};
    if (!locs.address_components.filter(item => item.types[0]==='sublocality_level_1')) 
      {location.sublocality_level_1=''};

    location.area = location.sublocality_level_2 +', ' +location.sublocality_level_1;
  }

  const handleChange = async(key, val) => {
    setLocation({ ...location, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form) {
      setLoading(true);

      const datx = {
        data: {
          usid: usrx.usid,
          form: 'us', 
          locs: {
            name: 'home', // default
            form: 'home',
            main: true, // main address
            perm: false, // permanent address
            comm: false, // communication address
            lobl: location.premise,  
            losr: location.route,
            loar: location.area,
            loct: location.locality,
            lodt: location.administrative_area_level_2,
            lost: location.administrative_area_level_1,
            locn: location.country,
            loco: location.postal_code,
            locx:'',
            locy:'',
            loid: place,
            actv: true
          }
        },
        user: usrx.usid,
        form: 'us'
      }
      console.log( JSON.stringify(datx) );
      const response = await fmupdateUserLocs( JSON.stringify(datx) );
      
      if (response.data) {
        history.push(`/onboard/contact`);
      }
      setLoading(false);
      //resetForm();
    }  
    else {
      setText('Please enter address information')
    }
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 60
      }}
    />


    {/* input */}
    <div className="p-3">
    <LocationSearchBar parentCallback = {userLocation}/>
      <p className="text-mini text-muted">
        Enter Adress and Search, Update missing fields below and Save
      </p>
      <div className="border-bottom"></div>
    </div>

      
    <form onSubmit={handleSubmit}>
      <div className="p-3">

      <div className="mb-3 d-none">
          <div className="form-group">
            <label className="form-label small">Building <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={location.premise}
              onChange={({ target }) => {handleChange("premise", target.value);}}
              placeholder="Enter Building No, Name">
            </input>
          </div>
        </div>

        <div className="mb-3 d-none">
          <div className="form-group">
            <label className="form-label small">Street <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={location.route}
              onChange={({ target }) => {handleChange("route", target.value); setText('');}}
              placeholder="Enter Street">
            </input>
          </div>
        </div>

        <div className="mb-3 d-none">
          <div className="form-group">
            <label className="form-label small">Area <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={location.area}
              onChange={({ target }) => {handleChange("area", target.value); setText('');}}
              placeholder="Enter Area">
            </input>
          </div>
        </div>

      <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">City <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={location.locality}
              onChange={({ target }) => {handleChange("locality", target.value); setText('');}}
              placeholder="Enter City">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">District <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={location.administrative_area_level_2}
              onChange={({ target }) => {handleChange("administrative_area_level_2", target.value); setText('');}}
              placeholder="Enter District">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">State <span className="text-danger">*</span></label>
            <select className="form-select height-md bg-light "
              value={location.administrative_area_level_1}
              onChange={({ target }) => handleChange("administrative_area_level_1", target.value)}
            >
              <option>Select State</option>
              {stateList.map((item,i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label text-medium">Country</label>
            <select className="form-select height-md bg-light "
              disabled
              value={location.country}
              onChange={({ target }) => handleChange("country", target.selectedIndex)}
            >
              <option>Select Country</option>
              <option value="India">India</option>
            </select>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">PIN Code <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={location.postal_code}
              onChange={({ target }) => {handleChange("postal_code", target.value); setText('');}}
              placeholder="Pin Code">
            </input>
          </div>
        </div>        

        <small className="text-danger">{text}</small>
      </div>
      
      <div className="p-3">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>
      
    </form>

  </>
  )
}