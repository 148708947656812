import { useEffect, useState } from "react";
import { useRouteMatch } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../uiux/site-loader';
import { HeaderInner } from '../uiux/hedr-inner';

import { fmgetUserLinkedAccountsList, fmUpdateUserLinkedAccountsMain } from '../../apis/realm-acnt';

export function SettingsAccountsMain(...args) {

  const asset = arguments[0].children;
  
  let {path} = useRouteMatch();
  const [sets, setSettings] = useState((path).toString().substr(13).charAt(0).toUpperCase() + (path).toString().substr(14));

  const [loading,setLoading]=useState(true);
  const [text, setText] = useState("");
  const [stat, setStatus] = useState(false);
  
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);

  useEffect( () => {
    if (asset){
      const resp = async() => {
        setList((await fmgetUserLinkedAccountsList(JSON.stringify({ 
          data: {
            usid: asset._id.toString(),
            form: asset.form
          },
          user: asset._id.toString(),
          form: asset.form
        })
        )).data);

        setLoading(false);
      }
      resp()
    } else {}
  },[]);

  const handleChange = (indx, stat) => {
    setList(list => list.map((item, i) => (indx === i 
      ? { ...item, ["main"]: true } 
      : { ...item, ["main"]: false })));

    updateSettings(indx);
  }

  const updateSettings = async(indx) => {
    //console.log (list[indx].acnr)
    setText('')
    const datx = {
      data: {
        usid: asset._id.toString(),
        form: asset.form,
        acid: list[indx]._id.toString()
      },
      user: asset._id.toString(),
      form: asset.form
    }

    console.log (datx)
    const response = await fmUpdateUserLinkedAccountsMain(JSON.stringify(datx));
    console.log (response);
    if (response) {
      toast.success(`Primary Account Updated`);
      //setText('Primary Account Updated');
      setStatus(true);
    }
    else {
      toast.error(`Primary Account not Updated`);
      //setText('Primary Account not updated'); 
    }
  }

  const handleSubmit = async (e) => {

  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <Toaster/>
    <HeaderInner 
      dataFromParent = {
      { name: `Settings: Primary Account`,
        desc: `Manage Your Primary Account`,
        link: `/${asset.form}/settings/accounts`
      }}
    />
    
      {/* main */}
      <div className="p-1 pt-3">
        <div className="row g-0 mb-3 border-bottom">
          <div className="col">
            <p className="text-primary">Linked Accounts</p>  
          </div>
          <div className="col text-end py-1">
            <p className="text-muted small">Primary</p>  
          </div>
        </div>
        
        
        {list && list.length > 0 && list.map((item, i) => (
        <div className="row g-0 mb-3" key={i}>
          
          <div className="col">
            <p className="fw-bold m-0 p-0 text-uppercase">{item.acnr}</p>
            <p className="small m-0 p-0 text-uppercase">{item.acfr}{'-'}{item.acsr}</p>
          </div>  
          <div className="col-3 text-end">
            <div className="m-0 p-0">
              <div className="form-check form-switch ps-0 mb-3">
                <div className="d-flex m-0 p-0 py-1">
                  <div className="me-auto"></div>
                    <div className="m-0 p-0">
                      <input  className="form-check-input" 
                        style={{fontSize:"1.25em"}}
                        type="checkbox"
                        checked={item.main}
                        onChange={(e) => handleChange(i, e.target.checked)}
                      ></input>
                    </div> 
                  </div>
              </div>
            </div>
          </div>  
        </div>
        ))}
      </div>
 
      <div className="p-1 pt-3">
        <small className="text-muted">
          Primary Account is DEFAULT for all deposits from MoneyOrder. 
          You can setup your bank account or UPI account as the Primary Account.
        </small>
      </div>

      {/* status */}
      <div className="p-1 pt-3">
        <small className={`text-${stat?'success':'danger'}`}>
          {text}
        </small>
      </div>

      {/* action */}

  </>
  )
}