import { useState } from "react";
import { useRouteMatch } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import { HeaderInner } from '../uiux/hedr-inner';

export function SettingsSubs(...args) {

  const asset = arguments[0].children;
  
  let {path} = useRouteMatch();
  const [sets, setSettings] = useState((path).toString().substr(13).charAt(0).toUpperCase() + (path).toString().substr(14));

  const [loading,setLoading]=useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderInner 
      dataFromParent = {
      { name: `Settings: ${sets}`,
        desc: `Manage Your ${sets}`,
        link: `/${asset.form}/settings`
      }}
    />

    <form onSubmit={handleSubmit}>
      {/* basic info */}
      <div className="p-1">
        <p className="text-primary d-none">Coming Soon</p>  
        <img src="https://image.freepik.com/free-vector/flat-coming-soon-background_52683-58443.jpg"
          className="img-fluid" alt="..."
        ></img>

      </div>


    </form>

  </>
  )
}