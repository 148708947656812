//loader
export function Spinner(...args) {
 
  return (
  <>
      {/* spinner */}
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
  </>
  )
}