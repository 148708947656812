import LayoutCenter from '../../comp/site/layout-center';
import { getLocalUser } from '../../apis/realm-auth';

import SettingsMain from '../../comp/sets/settings-main';

export default function SettingsList() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsMain>
        {getLocalUser()}
      </SettingsMain>

      </LayoutCenter>
  )
}