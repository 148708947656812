import LayoutAuth from '../comp/site/layout-auth';
import AuthRegister from '../comp/auth/register';

export default function Register() {
    return (
      <LayoutAuth>
        <AuthRegister />
      
      </LayoutAuth>
    )
  }