import { useState } from "react";
import { Link } from "react-router-dom";

import { Loader } from '../uiux/site-loader';
import { HeaderInner } from '../uiux/hedr-inner';

import SettingsUserCard from './settings-user-card';

const pars = (require("../../data/sets-menulist.json")).data;

export default function SettingsMain(...args) {

  const asset = arguments[0].children;
  const [loading, setLoading] = useState(false);
  const list = (pars.find(item => item.form === asset.form && item.locc === "sets")).list;

  if (loading){
    return (
      <Loader></Loader>
    )
  }

  return (
  <>
    <HeaderInner 
      dataFromParent = {
      { name: `Account Settings`,
        desc: `Manage Your Account Settings`,
        link: `/${asset.form}/dashboard`
      }}
    />

      {/* user */}
      <div className="p-1 pt-3">
        <SettingsUserCard>
          {asset}
        </SettingsUserCard>
      </div>
      
      {/* settings */}
      <div className="px-1">
      {list && list.map((item, i) => (
        <Link to={`/${asset.form}/settings${item.link}`} key={i}>
        <div className="d-flex py-3 border-bottom">
          <div className="">
            <i className={`m-0 p-0 ${item.icon}`}  
                style={{fontSize:"1.5em", lineHeight:"0"}}>
            </i>
          </div>

          <div className="ps-3">
            <p className="m-0 p-0">{item.name}</p>
          </div>

          <div className="ms-auto">
            <i className={`m-0 p-0 text-muted bi bi-chevron-right`}  
                style={{fontSize:"1.5em", lineHeight:"0"}}>
            </i>
          </div>
        </div>
        </Link>
      ))}
      </div>

      {/* logout */}
      <div className="px-1">
        <Link to={`/account/logout`}>
          <div className="d-flex py-3">
            <div className="">
              <i className={`m-0 p-0 bi bi-power`}  
                  style={{fontSize:"1.5em", lineHeight:"0"}}>
              </i>
            </div>

            <div className="ps-3">
              <p className="m-0 p-0">Logout</p>
            </div>

            <div className="ms-auto">

            </div>
          </div>
        </Link>
      </div>

  </>
  )
}