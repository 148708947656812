// user onboard - contact info - mobile, email

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

import { getAuthUser, getNewUser } from '../../apis/realm-auth';
import { fmsendPasscode, fmverifyPasscode } from '../../apis/realm-util';
import { fmupdateUserComs } from '../../apis/realm-accs';

export function OnboardUserComs() {
  
  const asset = getAuthUser();
  const usrx = getNewUser();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [mmidcode,setMmidcode]=useState('');
  const [mailcode,setMailcode]=useState(false);
  const [mailPasscode,setMailPasscode]=useState('');
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    mmid: isNaN(asset.auth) ? '' : asset.auth,
    mmvr: isNaN(asset.auth) ? false : true,
    emid: isNaN(asset.auth) ? asset.auth : '',
    emvr: isNaN(asset.auth) ? true : false,
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.mmvr && data.emvr) 
      setForm(true);
  },[data.mmvr, data.emvr]);


  const sendMailPasscode = async() => {
    setText('Sending Passcode...');
    setMailcode(true);

    var mail = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
    if (mail.test(data.emid) ){
      const datx = {
        data: {
          user: data.emid,
          form: 'emid',
          resn: 'Email Verification',
          site: 'SettleFirst'
        }
      }
      
      const response  = (await fmsendPasscode (JSON.stringify(datx))).data
      console.log (response);
      setText('Passcode has been sent')
      
    }
    else {
      setText('Invalid Email')
      setMailcode(false);
    }
  }

  const verifyMailPasscode = async() => {
    
    const datx = {
      data: {
        user: data.emid,
        form: 'emid',
        code: mailPasscode
      }
    }
    const response  = (await fmverifyPasscode (JSON.stringify(datx))).data
    console.log (response);
    if (response) {
      handleChange('emvr', true);
      setText('Passcode Verified')
    }
    else {
      handleChange('emvr', false);
      setText('Incorrect Passcode')
    }
  }

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) 
    {
      setLoading(true);

      const datx = {
        data: {
          coms: [
            {form:'emid', nmbr: data.emid, stat:data.emvr},
            {form:'mmid', nmbr: data.mmid, stat:data.mmvr}
          ],
          usid: usrx.usid,
          form: 'us'
        },
        user: usrx.usid,
        form: 'us'
      }
      
      console.log( JSON.stringify(datx) );  
      const response = await fmupdateUserComs( JSON.stringify(datx) );
    
      if (response.data) {
        console.log (response);
        history.push(`/onboard/terms`);
      }

      setLoading(false);
    }
    else {
      setText('Enter Email')
    }
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 79
      }}
    />


    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="p-3">

        <div className="mb-3">
          <div className="form-group mb-3">
            <label className="form-label small">Mobile <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              disabled={data.mmvr}
              value={data.mmid}
              onChange={({ target }) => {handleChange("mmid", target.value); setText('');}}
              placeholder="9876540123">
            </input>
          </div>
          <p className="small">
            {<i className={`bi-circle-fill small text-${!data.mmvr? 'lite' : 'success'}`}></i>}
            {' '}{data.mmvr? 'Verified' : 'Not Verified'}
          </p>
        </div>

        <div className="mb-3">
          <div className="form-group mb-3">
            <label className="form-label small">Email <span className="text-danger">*</span></label>
            <input type="email" className="form-control height-md bg-soft"
              disabled={data.emvr}
              value={data.emid}
              onChange={({ target }) => {
                handleChange("emid", target.value); setText('');
                setText('');
                setMailcode(false);
                setMailPasscode('');
              }}
              placeholder="user@mail.com">
            </input>
          </div>

          <div className={`form-group mb-3 ${mailcode?'':'d-none'}`}>
            <label className="form-label small">Passcode (6 digit) <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"              
              value={mailPasscode}
              onChange={({ target }) => {
                setMailPasscode(target.value); setText('');
                setForm(false)
              }}
              placeholder="123456">
            </input>
          </div>
          <p className="small">
            {<i className={`bi-circle-fill small text-${!data.emvr? 'lite' : 'success'}`}></i>}
            {' '}{data.emvr? 'Verified' : 'Not Verified'}
          </p>
        </div>


        <small className="text-danger">{text}</small>
      </div>
      
      <div className="p-3">
        
        <div className={mailcode ? 'd-none':'d-grid'}>
          <button className={`btn height-md ${ data.emid!=='' ? 'btn-outline-primary' : 'btn-light'}`}
            disabled={data.emid===''}
            type="button"
            onClick={()=>sendMailPasscode()}
          ><small>Get Passcode</small>
          </button>
        </div>

        <div className={!form && mailcode ? 'd-grid':'d-none'}>
        <button className={`btn height-md ${ mailcode && mailPasscode.length===6 ? 'btn-outline-primary' : 'btn-light'}`}
            disabled={mailPasscode.length!==6}
            type="button"
            onClick={()=>verifyMailPasscode()}
          ><small>Verify Passcode</small>
          </button>
        </div>

        <div className={form?'d-grid':'d-none'}>
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>

      </div>

    </form>

  </>
  )
}