import LayoutAuth from '../comp/site/layout-auth';
import { Spinner } from '../comp/uiux/site-spinner';

import { fmlogoutUser } from '../apis/realm-auth';

export default function Logout() {
  
  fmlogoutUser();

  return (
    <LayoutAuth>
      <div className="text-center">
        <div style={{height:"24vh"}}></div>
        <Spinner />
        <div className="py-3"></div>
        <p className="small text-muted">Logout User</p> 
      </div>
      
    </LayoutAuth>
  )
}