// Web Navigation
import { Link } from "react-router-dom";

import { ActiveLink } from '../../apis/app-helper';

import logo from '../../brand/logo.png';
import avtx from '../../images/user.png';

const navs = (require("../../data/navs-web.json")).data;
const list = (navs.find(item => item.locc === "head")).list;

function WebHeader() {

  return (
  <>
    {/* nav - large */}
    <div className="border-bottom bg-white d-none d-md-block">
      <div className="container-fluid">
        <div className="row py-1">
          {/* brand */}

          <div className="col-1 d-none">
            <nav className="nav small justify-content-start py-2">
              <img src={logo} className="" height="37" alt="..." />              
            </nav>
          </div>
          
          {/* nav */}
          <div className="col">
            
            <nav className="nav justify-content-start py-3">
              {list && list.map((item, i) => ( item.actv ?
              <Link to={`${item.link}`} key={i}
                className={`nav-link fw-bold small m-0 py-0 ps-0  ${(item.link).slice(1) === ActiveLink() ? 'link-primary' : 'text-muted'}`}>
                {item.name}
              </Link>
              :''))}
            </nav>

          </div>

          {/* user */}
          <div className="col-md-3 text-end">
            <nav className="nav small justify-content-end py-2 mt-1">
                <div className="pe-3">
                  <Link to={`/notifications`} 
                    className="btn btn-sm btn-outline-primary rounded-md">
                    <small>Create X</small>
                  </Link>
                </div>

              <Link to={`/settings`}>
                <img src={avtx} 
                  className="rounded-circle shadow-sm border" 
                  width="30" height="30" alt="..." />
              </Link>
            </nav>
          </div>

        </div>
      </div>
    </div>

    {/* nav - mobile */}

  </>
  )
}

export default WebHeader;