import { Link } from 'react-router-dom';

import LayoutAuth from '../site/layout-auth';
import logotext from '../../brand/logo-text.png';

export function OnboardUserSuccess() {

  return (
  <>
    <LayoutAuth>
      <div className="p-3">
        <img src={logotext} height="20" alt="..." />
      </div>

      {/* main */}
      <div className="p-3">
        <div className="layout-landscape">
          <img src="https://img.freepik.com/free-photo/corporate-business-handshake-partners_53876-96139.jpg?size=338&ext=jpg"
            className="img-fluid" alt="..."></img>
        </div>
        <div className="pt-1 bg-success"></div>
        <div className="d-flex bg-soft p-3">
          <div className="">
            <i className={`bi-gift text-primary`}  
              style={{fontSize:"2.1em", }}>
            </i>
          </div>
          <div className="ps-3">
            <p className="lead m-0 p-0">Congratulations !</p>
            <small className="text-muted">Your Profile is Updated</small>
          </div>
          
        </div>

      </div>

      <div className="p-3">
      
        <p>We are processing your account information.</p>
        <p>We will notify you once your account is ready.</p>
      </div>


      <div className="p-3">
        <hr></hr>
        <Link to={`/account/logout`}
          className="link-danger"
        ><small>Logout</small></Link>
      </div>
    
    </LayoutAuth>
  </>
  )
} 