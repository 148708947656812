import React from "react";
import { Link } from 'react-router-dom';

export class HeaderInner extends React.Component {
  constructor(props) {
    super(props);
    this.item = this.props.dataFromParent;
  }

  render() {
    return (
      <>
      <div className="p-1">
        <div className="d-flex">
          <div className="me-auto">
            <p className="lead text-primary m-0 p-0">{this.item.name} </p>
            <small className="">{this.item.desc}</small>
          </div>
          <div className="">
            <Link 
              className="text-secondary"
              to={this.item.link}
            >
            <i className='bi-x' 
              style={{fontSize:"2em", lineHeight:"0"}}></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="py-3"></div>
      </>
    );
  }
}