import React from "react";
const AWS = require('aws-sdk');

const awsConfig = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
}

AWS.config.update(awsConfig)
// Create S3 service object
const s3 = new AWS.S3({ apiVersion: '2006-03-01' })
const BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME_DOCS

export default class AWSDocUpload extends React.Component {

  constructor (props) {
    super(props)
    this.state = { fileObject: null}
    this.onFileInputChange = this.onFileInputChange.bind(this)
    this.onUpload = this.onUpload.bind(this)
  }

  onFileInputChange = async e => {
    const { files } = e.target
    this.setState({ fileObject: files[0] })
  }

  onUpload = async () => {
    const { fileObject } = this.state
    const filename = //Date.parse(new Date())/1000 
      this.props.dataFromParent 
      + '.'
      + fileObject.name.split('.')[(fileObject.name.split('.')).length-1]

    const params = {
      Bucket: BUCKET_NAME, // pass your bucket name
      Key: filename || fileObject.name,
      Body: fileObject,
      //ContentType: 'image/jpeg'
      ContentType: fileObject.type,
    }

    //console.log (this.props.dataFromParent)
    //console.log(params);

    s3.upload(params, (s3Err, s3data) => {
      if (s3Err) {
        //console.log('err', s3Err)
        this.props.parentCallback('errr');
      }
      else {
        //console.log(`File uploaded successfully at ${s3data.Location}`)
        this.props.parentCallback(s3data.Location);
      }

      //uploaded img url
      //https://docsa37mkbbmxo5.s3.ap-south-1.amazonaws.com/img.jpeg
    })
    
  }

  render () {
    const { fileObject } = this.state
    return (
      <div>
        <div className="row">
          <div className="col-9">
            <input type='file' name='doc'
              className="border-none bg-white small"
              onChange={this.onFileInputChange}>
              
            </input>
          </div>
          <div className="col-3">
            <button disabled={!fileObject} 
              type="button"
              className="btn btn-sm btn-outline-primary rounded-md"
              onClick={this.onUpload}>
              <small>Upload</small>
            </button>
          </div>
        </div>
        <div className="row">

        </div>
      </div>
    )
  }
}