// new user account created here
// user onboard - name, photo, mmid or emid, obnm = true

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

import { getAuthUser, setNewUser, getNewUser } from '../../apis/realm-auth';
import { fmupdateUserName, fmonboardNewUser } from '../../apis/realm-accs';

export function OnboardUserName() {
  
  const asset = getAuthUser();
  const usrx = getNewUser();

  const history = useHistory();
  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    nmfs: '',
    nmmi: '',
    nmls: '',
    mmid: isNaN(asset.auth) ? '' : asset.auth,
    mmvr: isNaN(asset.auth) ? false : true,
    emid: isNaN(asset.auth) ? asset.auth : '',
    emvr: isNaN(asset.auth) ? true : false
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.nmfs!=='' && data.nmls!=='') 
      setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) 
    {
      setLoading(true);

       if (usrx.usid!=='') {
        data.usid = usrx.usid;
        data.form = 'us';
       }
      console.log (data.nmfs, data.nmmi, data.nmls);
      data.name = (data.nmfs+" "+data.nmmi+" "+data.nmls).replace(/\s+/g,' ').trim();
      delete data.nmfs; delete data.nmmi; delete data.nmls;

      const datx = {
        data: data,
        user: (usrx.usid!=='') ? usrx.usid : asset.usrx,
        form: 'us'
      }
      //console.log(asset);
      console.log( JSON.stringify(datx) );
      
      var response = {} 
      if (usrx.usid==='') response = await fmonboardNewUser( JSON.stringify(datx) )
      else response = await fmupdateUserName( JSON.stringify(datx) )

      if (response.data) {
        console.log (response);

        setNewUser({usid:response.data})
        history.push(`/onboard/domain`);
      }
      
      setLoading(false);
      //resetForm();
    }
    else {
      setText('Please enter Full Name (First Name Last Name)')
    }
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 6
      }}
    />


    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="p-3">

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">First Name <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={data.nmfs}
              onChange={({ target }) => {handleChange("nmfs", target.value); setText('');}}
              placeholder="Enter First Name">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Middle Name</label>
            <input type="text" className="form-control height-md bg-soft"
              value={data.nmmi}
              onChange={({ target }) => {handleChange("nmmi", target.value); setText('');}}
              placeholder="Enter Middle Name">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Last Name <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              value={data.nmls}
              onChange={({ target }) => {handleChange("nmls", target.value); setText('');}}
              placeholder="Enter Last Name">
            </input>
          </div>
        </div>


        <small className="text-danger">{text}</small>
      </div>
      
      <div className="p-3">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

  </>
  )
}