import { useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import { HeaderInner } from '../uiux/hedr-inner';

import { fmgetUserSettingsDocs, fmUpdateUserSettingsDocs } from '../../apis/realm-accs';

export function SettingsDocs(...args) {

  const asset = arguments[0].children;
  const history = useHistory();

  let {path} = useRouteMatch();
  const [sets, setSettings] = useState((path).toString().substr(13).charAt(0).toUpperCase() + (path).toString().substr(14));

  const [loading,setLoading]=useState(true);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);

  useEffect( () => {
    if (asset){
      const resp = async() => {
        setData((await fmgetUserSettingsDocs(JSON.stringify({ 
          data: {
            usid: asset._id.toString(),
            form: asset.form
          },
          user: asset._id.toString(),
          form: asset.form})
        )).data);
        setLoading(false);
      }
      resp()
    } else {}
  },[]);

  const handleChange = (key, val, indx) => {
    // edit row data
    setData(data.map((item, i) => (indx === i ? { ...item, [key]: val } : item)));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const datx = {
      data: {
        usid: asset._id.toString(),
        acnt: data 
      },
      form: asset.form,
      user: asset._id.toString()
    }
    //console.log(asset);
    console.log( JSON.stringify(datx) );
    const response = await fmUpdateUserSettingsDocs( JSON.stringify(datx) );
    console.log (response)
    setLoading(false);
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderInner 
      dataFromParent = {
      { name: `Settings: ${sets}`,
        desc: `Manage Your ${sets}`,
        link: `/${asset.form}/settings`
      }}
    />

    <form onSubmit={handleSubmit}>
      {/* basic info */}
      <div className={`p-1`}>
        <p className="text-primary">Documents</p>  

        {data && data.length > 0 && data.map((item, i) => (

        <div className="card border-none rounded-none p-0 py-2 border-bottom" key={i}>
          
            <div className="row g-0 py-2 ">
              <div className="col">
                <div className="card-body p-0 text-dark">
                  <p className="card-title fw-bold m-0 p-0">{item.name}</p>
                  <p className="card-text small">{item.nmbr}</p>

                  <p className={`small ${item.stat? '' : 'd-none'}`}>
                    <span className={`small text-${item.dcvr ? 'success' : 'lite'}`}>●</span>
                    <span className="text-dark">{' '}{item.dcvr ? 'Verified' : 'Not Verified'}</span>
                  </p>
                  <a href={item.file} 
                    className = {`small ${item.stat ? 'text-primary' : 'text-muted'}`}
                    style={{pointerEvents:`${item.stat ? 'auto' : 'none'}`}}
                  >
                  {item.stat ? 'Download File' : 'Not Provided'}
                  </a>

                </div>
              </div>
              <div className="col-2 text-end">
                <div className="p-0">
                  <Link to={`/${asset.form}/settings/documents/edit/${item.form}`}
                    className="btn btn-sm btn-light text-primary px-3"
                  ><small>Edit</small>
                  </Link>
                </div>
              </div>
            </div>
          
        </div>
        ))}

      </div>

    </form>

  </>
  )
}