// Realm App
import * as Realm from 'realm-web';
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_MAIN });

// Function Calls

// individual users

// get user info by user-id
export const fmgetUserName = async (id) => {
  const result = app.currentUser.functions.accsGetAccountName(JSON.stringify({
    data:{usid:id, form:'us'}, 
    user:id, form: 'us'
  }));
  return result;
}

// get user info by user-id
export const fmgetUserInfo = async (id) => {
  const result = app.currentUser.functions.accsGetAccountInfo(JSON.stringify({
    data:{usid:id, form:'us'},
    user:id, form: 'us'}));
  return result;
}

// search if user is business owner
// to update
export const fmverifyBusinessOwner = async (item) => {
  const result = app.currentUser.functions.accsVerifyBusinessOwner(item);
  return result;
}

// get user info by mobile, email, gstn, panc
export const fmSearchUserAccountID = async (item) => {
  const result = app.currentUser.functions.accsSearchUserAccountID(item);
  return result;
}

// onboard new user - step 1 (name)
export const fmonboardNewUser = async (item) => {
  const result = await app.currentUser.functions.accsOnboardNewUser(item)
  return (result);
}

// create new user via contact add
export const fmcreateNewUser = async (item) => {
  const result = await app.currentUser.functions.accsCreateNewUser(item)
  return (result);
}

// update user profile  during onboarding
export const fmupdateUserName = async (item) => {
  const result = await app.currentUser.functions.accsUpdateUserName(item)
  return (result);
}

export const fmupdateUserDomain = async (item) => {
  const result = await app.currentUser.functions.accsUpdateUserDomain(item)
  return (result);
}

export const fmupdateUserCreds = async (item) => {
  const result = await app.currentUser.functions.accsUpdateUserCreds(item)
  return (result);
}

export const fmupdateUserDocs = async (item) => {
  const result = await app.currentUser.functions.accsUpdateUserDocs(item)
  return (result);
}

export const fmupdateUserLocs = async (item) => {
  const result = await app.currentUser.functions.accsUpdateUserLocs(item)
  return (result);
}

export const fmupdateUserComs = async (item) => {
  const result = await app.currentUser.functions.accsUpdateUserComs(item)
  return (result);
}

export const fmupdateUserTerms = async (item) => {
  const result = await app.currentUser.functions.accsUpdateUserTerms(item)
  return (result);
}

export const fmgetUserSettingsProfile = async (item) => {
  // get user settings - info
  const result = await app.currentUser.functions.accsGetUserSettingsProfile(item)
  //console.log (result);
  return (result);
}

export const fmUpdateUserSettingsProfile= async (item) => {
  // get user settings - info
  const result = await app.currentUser.functions.accsUpdateUserSettingsProfile(item)
  return (result);
}

export const fmgetUserSettingsDocs = async (item) => {
  // get user settings - docs
  const result = await app.currentUser.functions.accsGetUserSettingsDocs(item)
  console.log (result);
  return (result);
}

export const fmUpdateUserSettingsDocs= async (item) => {
  // get user settings - docs
  const result = await app.currentUser.functions.accsUpdateUserSettingsDocs(item)
  return (result);
}


export const fmgetUserSettingsAccounts = async (item) => {
  // get user settings - accounts
  const result = await app.currentUser.functions.accsGetUserSettingsAccounts(item)
  //console.log (result);
  return (result);
}

export const fmUpdateUserSettingsAccounts= async (item) => {
  // get user settings - accounts
  const result = await app.currentUser.functions.accsUpdateUserSettingsAccounts(item)
  return (result);
}


export const fmgetUserSettingsPrivacy = async (item) => {
  // get user settings - privacy
  const result = await app.currentUser.functions.accsGetUserSettingsPrivacy(item)
  //console.log (result);
  return (result);
}

export const fmUpdateUserSettingsSearch = async (item) => {
  // get user settings - search
  const result = await app.currentUser.functions.accsUpdateUserSettingsSearch(item)
  return (result);
}

export const fmUpdateUserSettingsAlerts = async (item) => {
  // get user settings - alerts
  const result = await app.currentUser.functions.accsUpdateUserSettingsAlerts(item)
  return (result);
}



// business users

export const fmgetBusinessName = async (id) => {
  // get business name by user-id
  const result = app.currentUser.functions.accsGetAccountName(JSON.stringify({data:{usid:id, form:'bz'}, user:id, form: 'bz'}));
  return result;
}

export const fmgetBusinessInfo = async (id) => {
  // get business info by user-id
  const result = app.currentUser.functions.accsGetAccountInfo(JSON.stringify({data:{usid:id, form:'bz'}, user:id, form: 'bz'}));
  return result;
}

export const fmonboardNewBusiness = async (item) => {
  // onboard new business - step 1 (name)
  const result = await app.currentUser.functions.accsCreateNewBusiness(item)
  return (result);
}

export const fmupdateBusinessName = async (item) => {
  // update user settings - patch (xxx)
  const result = await app.currentUser.functions.accsUpdateBusinessName(item)
  return (result);
}

export const fmupdateBusinessCreds = async (item) => {
  // update business settings - patch
  const result = await app.currentUser.functions.accsUpdateBusinessCreds(item)
  return (result);
}

export const fmupdateBusinessDocs = async (item) => {
  // update business settings - patch
  const result = await app.currentUser.functions.accsUpdateBusinessDocs(item)
  return (result);
}

export const fmupdateBusinessLocs = async (item) => {
  // update business settings - patch
  const result = await app.currentUser.functions.accsUpdateBusinessLocs(item)
  return (result);
}

export const fmupdateBusinessComs = async (item) => {
  // update business settings - patch
  const result = await app.currentUser.functions.accsUpdateBusinessComs(item)
  return (result);
}

export const fmupdateBusinessTerms = async (item) => {
  // update business settings - patch
  const result = await app.currentUser.functions.accsUpdateBusinessTerms(item)
  return (result);
}