import LayoutAuth from '../comp/site/layout-auth';
import AuthNext from '../comp/auth/next';

export default function Next() {
    return (
      <LayoutAuth>
        <AuthNext />
      
      </LayoutAuth>
    )
  }