import LayoutAuth from '../comp/site/layout-auth';
import {OnboardUserDocs} from '../comp/onbd/user-docs';

export default function Onboard() {
  return (
    <LayoutAuth>
      <OnboardUserDocs />
      
    </LayoutAuth>
  )
}