import { useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../uiux/site-loader';
import { HeaderInner } from '../uiux/hedr-inner';

import avtr from '../../images/user.png';

import { fmgetUserLinkedAccountsMain } from '../../apis/realm-acnt';
import { fmgetUserSettingsAccounts, fmUpdateUserSettingsAccounts } from '../../apis/realm-accs';

export function SettingsAccounts(...args) {

  const asset = arguments[0].children;
  const history = useHistory();
  
  let {path} = useRouteMatch();
  const [sets, setSettings] = useState((path).toString().substr(13).charAt(0).toUpperCase() + (path).toString().substr(14));

  const [loading,setLoading]=useState(true);
  const [textTransfers, setTextTransfers] = useState('');
  const [statTransfers, setStatusTransfers] = useState(false);
  const [textReports, setTextReports] = useState('');
  const [statReports, setStatusReports] = useState(false);

  const [data, setData] = useState([]);
  const [list, setList] = useState({});

  useEffect( () => {
    if (asset){
      const resp = async() => {
        setData((await fmgetUserSettingsAccounts(JSON.stringify({ 
          data: {
            usid: asset._id.toString(),
            form: asset.form
          },
          user: asset._id.toString(),
          form: asset.form})
        )).data);

        setList((await fmgetUserLinkedAccountsMain(JSON.stringify({ 
          data: {
            usid: asset._id.toString(),
            form: asset.form
          },
          user: asset._id.toString(),
          form: asset.form
        })
        )).data);

        setLoading(false);
      }
      resp()
    } else {}
  },[]);

  const handleChange = async(form, key, val) => {
    setData({ ...data, [key]: val });
    updateSettings(form, key, val);
  }

  const updateSettings = async(form, key, val) => {
    //console.log (key, val)
    setTextTransfers('');
    setTextReports('');
    const datx = {
      data: {
        mode: key, stat: val,
        usid: asset._id.toString(),form: asset.form
      },
      user: asset._id.toString(),
      form: asset.form
    }

    //console.log (datx)
    const response = await fmUpdateUserSettingsAccounts(JSON.stringify(datx));
    //console.log (response);
    if (response) {
      if (form === "transfers"){
        toast.success(`Settings Updated`);
        //setTextTransfers('Settings Updated');
        setStatusTransfers(true);
      }
      if (form === "reports"){
        toast.success(`Settings Updated`);
        //setTextReports('Settings Updated');
        setStatusReports(true);
      }
    }
    else {
      if (form === "transfers"){
        toast.error(`Settings not Updated`);
        //setTextTransfers('Settings not Updated');
      }
      if (form === "reports"){
        toast.error(`Settings not Updated`);
        //setTextReports('Settings not Updated');
      }
    }
     
  }

  const handleSubmit = async (e) => {

  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <Toaster/>
    <HeaderInner 
      dataFromParent = {
      { name: `Settings: ${sets}`,
        desc: `Manage Your ${sets}`,
        link: `/${asset.form}/settings`
      }}
    />

      {/* main */}
      <div className="p-1">
        <p className="text-primary">Primary Account</p>  

        <div className={list ? 'd-none':''}>
          <div className="d-flex rounded bg-soft p-3">
            <div className="">
            <i className={`bi-exclamation-circle text-danger`}  
                  style={{fontSize:"2.1em", }}>
              </i>
            </div>
            <div className="ps-3">
              <p className="text-danger m-0 p-0">No Linked Accounts Found</p>

              <p className="small text-muted  m-0 p-0">
                Account Transfers and Account Reports Settings on this page are disabled
              </p>
            </div>

          </div>
          <div className="pb-3"></div>
          <Link to={`/${asset.form}/accounts/link`}
            className="btn btn-primary px-3"
          ><small>Click here to Link Account</small></Link>
        </div>

        <div className={`p-0 mb-3 ${list ? '':'d-none'}`}  >
          <div className="p-3 bg-soft rounded mb-3">
            <div className="row g-0">
              <div className="d-none" style={{width:"fit-content"}}>
                <img src={avtr } className="rounded" 
                  style={{objectFit:"cover"}} height="42" width="42" alt="..."></img>
              </div>

              <div className="col">
                <p className="m-0 p-0 text-uppercase">
                  {list.acnr}
                </p>
                <p className="m-0 p-0 small text-muted text-uppercase">
                  {list.acsr}
                </p>
              </div>

              <div className="text-end" style={{width:"fit-content"}}>
              <Link 
                className="link-primary small"
                to={`/${asset.form}/settings/accounts/main`}
              >
                Edit
              </Link>
              </div>
            </div>

          </div>

          <small className="text-muted">
            Primary Account is DEFAULT for all deposits from MoneyOrder. 
            You can setup your bank account or UPI account as the Primary Account.
          </small>
        </div>

      </div>
      
      <div className="py-3"></div>
      {/* transfer */}
      <div className="p-1">
        <p className="text-primary">Account Transfers</p>  
        
        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Credit Each Transactions</p>
              <p className="small text-muted m-0 p-0">Credit transactions will be settled individually as received</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {data.each}
                disabled = {!list}
                onChange={({ target }) => {
                  handleChange("transfers", "each", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Credit as Bulk Transaction</p>
              <p className="small text-muted m-0 p-0">Credit transactions will be consolidated into single transaction end of day </p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {data.bulk}
                disabled = {!list}
                onChange={({ target }) => {
                  handleChange("transfers", "bulk", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Clear Transit Account Balance</p>
              <p className="small text-muted m-0 p-0">Transit Account Balance will be credit back everyday and reset to 0</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {data.zero}
                disabled = {!list}
                onChange={({ target }) => {
                  handleChange("transfers", "zero", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        {/* status */}
        <div className="">
          <small className={`text-${statTransfers?'success':'danger'}`}>
            {textTransfers}<span className={`text-muted`}>...</span>
          </small>
        </div>


      </div>

      {/* reports */}
      <div className="p-1 pt-3">
        <p className="text-primary">Account Reports</p>  

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Daily Activity Reports</p>
              <p className="small text-muted m-0 p-0">Get Daily Activity Report (xls) in Email</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {data.daar}
                disabled = {!list}
                onChange={({ target }) => {
                  handleChange("reports", "daar", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Weekly Activity Reports</p>
              <p className="small text-muted m-0 p-0">Get Weekly Activity Report (xls) in Email</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {data.waar}
                disabled = {!list}
                onChange={({ target }) => {
                  handleChange("reports", "waar", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        <div className="form-check form-switch ps-0 mb-3">
          <div className="d-flex pt-3">
            <div className="me-auto">
              <p className="m-0 p-0">Monthly Account Reports</p>
              <p className="small text-muted m-0 p-0">Get Monthly Activity Report (xls) in Email</p>
            </div>  
            <div className="text-end">
              <input className="form-check-input m-0 p-0 lead"  
                checked = {data.maar}
                disabled = {!list}
                onChange={({ target }) => {
                  handleChange("reports", "maar", target.checked);
                }}
                type="checkbox">  
              </input>
            </div>
          </div>
        </div>

        {/* status */}
        <div className="">
          <small className={`text-${statReports?'success':'danger'}`}>
            {textReports}<span className={`text-muted`}>...</span>
          </small>
        </div>

      </div>


  </>
  )
}