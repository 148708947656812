import LayoutAuth from '../comp/site/layout-auth';
import {OnboardUserCreds} from '../comp/onbd/user-creds';

export default function OnboardCreds() {
  return (
    <LayoutAuth>
      <OnboardUserCreds />
      
    </LayoutAuth>
  )
}