// new user account created here
// user onboard - name, photo, mmid or emid, obnm = true

import { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

import { getNewUser } from '../../apis/realm-auth';
import { fmupdateUserTerms } from '../../apis/realm-accs';

export function OnboardUserTerms() {
  
  const usrx = getNewUser();
  const history = useHistory()

  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    info:false,
    legl:false,
    term:false,
    prvc:false
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.term && data.prvc) 
      setForm(true);
  });


  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (form) 
    {
      const datx = {
        data: {
          usid: usrx.usid,
          form: 'us'
        },
        user: usrx.usid,
        form: 'us'
      }
      //console.log( JSON.stringify(datx) );
      const response = await fmupdateUserTerms( JSON.stringify(datx) );
      
      if (response.data) {
        //console.log (response);
        history.push(`/onboard/success`);
      }
      //resetForm();
      setLoading(false);
    }
    else {
      setText('Please accept terms')
    }
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 91
      }}
    />


    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="p-3">

        <div className={`mb-3`}>
          <div className="form-check p-0">
            <div className="d-flex py-2">
            <div className="me-auto">
                <p className="m-0 p-0">I accept Terms & Conditions</p>
              </div>
              <div className="">
                <input  className="form-check-input" 
                  type="checkbox"
                  style={{fontSize:"1.1em"}}
                  checked={data.term}
                  onChange={({ target }) => handleChange("term", target.checked)}
                  >
                </input>
              </div> 
            </div>
          </div>
        </div>


        <div className={`mb-3`}>
          <div className="form-check p-0">
            <div className="d-flex py-2">
              <div className="me-auto ">
                <p className="m-0 p-0">I accept Privacy Policy</p>
              </div>
              <div className="m-0 p-0">
                <input  className="form-check-input" 
                  type="checkbox"
                  style={{fontSize:"1.1em"}}
                  checked={data.prvc}
                  onChange={({ target }) => handleChange("prvc", target.checked)}
                  >
                </input>
              </div> 
            </div>
          </div>
        </div>

        <small className="text-danger">{text}</small>
      </div>
      
      <div className="p-3">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

  </>
  )
}