// user onboard - creds - use pan card for both user and business

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Loader } from '../uiux/site-loader';
import {HeaderOnboard} from '../uiux/hedr-onboard';

import { getNewUser } from '../../apis/realm-auth';
import { fmupdateUserCreds } from '../../apis/realm-accs';

export function OnboardUserCreds() {
  
  const usrx = getNewUser();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    panc: '',
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.panc!=='' && data.panc.length===10) 
      setForm(true);
  },[data.panc]);


  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (form) 
    {

      const datx = {
        data: {
          usid: usrx.usid,
          form: 'us',
          docs: [
            {form:'panc', nmbr: data.panc}
          ]
        },
        user: usrx.usid,
        form: 'us'
      }
      console.log( JSON.stringify(datx) );
      const response = await fmupdateUserCreds( JSON.stringify(datx) );
      
      if (response.data) {
        history.push(`/onboard/docs`);
      }
      //resetForm();
      setLoading(false);
    }
    else {
      setText('Enter PAN Card Number')
    }
  }

  if (loading){
    return (
      <>
      <Loader></Loader>
      </>
    )
  }

  return (
  <>
    <HeaderOnboard 
      dataFromParent = {
      { name: 'Account Setup',
        desc: 'Please Update Your Profile',
        link: `/account/next`,
        stat: 19
      }}
    />


    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="p-3">

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">PAN Card Number <span className="text-danger">*</span></label>
            <input type="text" className="form-control height-md bg-soft"
              required
              value={data.panc}
              onChange={({ target }) => {handleChange("panc", target.value); setText('');}}
              placeholder="PAN Card Number">
            </input>
          </div>
        </div>

        <small className="text-danger">{text}</small>
      </div>
      
      <div className="p-3">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

  </>
  )
}