
import LayoutCenter from '../../comp/site/layout-center';
import { getLocalUser } from '../../apis/realm-auth';

import {SettingsAccounts} from '../../comp/sets/settings-accounts';

export default function SettingsAccountsUs() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsAccounts>
        {getLocalUser()}
      </SettingsAccounts>

      </LayoutCenter>
  )
}