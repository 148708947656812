import LayoutCenter from '../../comp/site/layout-center';
import { getLocalUser } from '../../apis/realm-auth';

import {SettingsDocs} from '../../comp/sets/settings-docs';

export default function SettingsDocsUs() {  
  
  return (
    <LayoutCenter>

      <div className="pb-3"></div>
      <SettingsDocs>
        {getLocalUser()}
      </SettingsDocs>

      </LayoutCenter>
  )
}