import Routes from "./routes/routes";
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';

function App() {
  return (
    <>
      <Router>
        <Routes />
      </Router>
    </>
  );
}

export default App;