import { Switch, Route } from "react-router-dom";

//pages - site
import Home from '../site/home';

//pages - dashboard
import Dashboard from '../pages/dashboard/dashboard';

// pages - worksapce
import Menu2 from '../pages/menu2/menu2';
import Menu3 from '../pages/menu3/menu3';
import Menu4 from '../pages/menu4/menu4';
import Menu5 from '../pages/menu5/menu5';
import Menu6 from '../pages/menu6/menu6';



//pages - common

// pages settings
import SettingsList from '../pages/settings/settingslist';
import SettingsProfile from '../pages/settings/settingsprofile';
import SettingsDocs from '../pages/settings/settingsdocs';
import SettingsAccounts from '../pages/settings/settingsaccounts';
import SettingsAccountsMain from '../pages/settings/settingsaccountsmain';
import SettingsPrivacy from '../pages/settings/settingsprivacy';
import SettingsReferrals from '../pages/settings/settingsreferrals';
import SettingsSubs from '../pages/settings/settingssubs';


//pages - auth - onbd
import Login from '../auth/login-mobile';
import Register from '../auth/register';
import Next from '../auth/next';
import Logout from '../auth/logout';
import OnboardName from '../onboard/onboardname';
import OnboardAvatar from '../onboard/onboardavatar';
import OnboardDomain from '../onboard/onboarddomain';
import OnboardCreds from '../onboard/onboardcreds';
import OnboardDocs from '../onboard/onboarddocs';
import OnboardLocation from '../onboard/onboardlocation';
import OnboardComs from '../onboard/onboardcoms';
import OnboardTerms from '../onboard/onboardterms';
import { OnboardUserSuccess } from "../comp/onbd/user-success";

function Routes() {
  
  return (
    <Switch>
      {/* public */}
      <Route path="/" component={Home} exact />
      <Route path="/home" component={Home} exact />
  
      {/* user */}
      <Route path="/dashboard" component={Dashboard} exact />


      {/* workspace */}
      <Route path="/menu2" component={Menu2} exact />
      <Route path="/menu3" component={Menu3} exact />
      <Route path="/menu4" component={Menu4} exact />
      <Route path="/menu5" component={Menu5} exact />
      <Route path="/menu6" component={Menu6} exact />

      {/* settings */}
      <Route path="/settings" component={SettingsList} exact />
      <Route path="/settings/profile" component={SettingsProfile} exact />
      <Route path="/settings/documents" component={SettingsDocs} exact />
      <Route path="/settings/accounts" component={SettingsAccounts} exact />
      <Route path="/settings/accounts/main" component={SettingsAccountsMain} exact />
      <Route path="/settings/privacy" component={SettingsPrivacy} exact />
      <Route path="/settings/referrals" component={SettingsReferrals} exact />
      <Route path="/settings/subscriptions" component={SettingsSubs} exact />

      {/* login, register, onboard */}
      <Route path="/account/login" component={Login} exact />
      <Route path="/account/register" component={Register} exact />
      <Route path="/account/next" component={Next} exact />
      <Route path="/account/logout" component={Logout} exact />

      <Route path="/onboard/name" component={OnboardName} exact />
      <Route path="/onboard/avatar" component={OnboardAvatar} exact />
      <Route path="/onboard/domain" component={OnboardDomain} exact />
      <Route path="/onboard/creds" component={OnboardCreds} exact />
      <Route path="/onboard/docs" component={OnboardDocs} exact />
      <Route path="/onboard/location" component={OnboardLocation} exact />
      <Route path="/onboard/contact" component={OnboardComs} exact />
      <Route path="/onboard/terms" component={OnboardTerms} exact />
      <Route path="/onboard/success" component={OnboardUserSuccess} exact />

    </Switch>
  );
}

export default Routes;