import React from "react";

export class UserAccountBalance extends React.Component {
  constructor(props) {
    super(props);
    this.item = this.props.dataFromParent;
  }

  render() {
    return (
      <>
      <div className="container-fluid">
        <div className="d-flex bg-soft border rounded p-3">
    
          <div className="me-auto">
            <p className="m-0 p-0">Account Balance</p>
            <p className="small text-muted m-0 p-0">Last Updated: </p>
          </div>
        
          <div className="">
            <p className='lead fw-bold'>{this.item}</p>
          </div>

        </div>        
      </div>      
      </>
    );
  }
}