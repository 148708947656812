//loader
export function Loader(...args) {
 
  return (
  <>
      {/* web */}
      <div className="container-fluid py-3" style={{minHeight:"96vh"}}>
        <div className="row">
          <div className="col text-center">
          <div className="" style={{height:"24vh"}} ></div>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="small text-muted m-0 pt-3 pb-0"
            >Please wait...</p>
            <p className="small text-muted py-3 d-none"
            >{arguments[0].children}</p>
          </div>
        </div>
      </div>
  </>
  )
}